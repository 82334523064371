*::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-color: #f5f5f5;
}

*::-webkit-scrollbar {
  width: 10px;
  height: 10px;
  background-color: #f5f5f5;
}

*::-webkit-scrollbar-thumb {
  background-color: rgb(205, 205, 205);
}


a {
  color: inherit;
  text-decoration: none;
}

.fullpage-loader {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background-color: $black;
  color: $white;
}


h1 {
  @include font(Inter-Regular, 15px, 24px, 0.93px, $exhibify-grey);
}

h2 {
  @include font(Inter-Regular, 13px, 24px, 0.93px, $exhibify-grey);
  text-transform: uppercase;
}

h3 {
  @include font(Inter-SemiBold, 14px, 24px, 0.93px, $exhibify-grey);
}

.warn-text {
  @include font(Inter-Regular, 11px, 18px, 0.93px, $warning);
  text-transform: uppercase;
}

.info-text {
  @include font(Inter-Regular, 12px, 18px, 0.93px, $info);
}

span,
p,
body {
  font-family: Inter-Regular, Arial, Helvetica Neue, Helvetica, sans-serif;
  font-size: 15px;
  line-height: 24px;
  letter-spacing: normal;
  font-style: normal;
}