.inspector {
  background-color: white;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: $inspector-panel-width;
  overflow-y: auto;
  overflow-x: hidden;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  box-sizing: border-box;
  height: 100vh;
  padding-top: 24px;
  border-left: 1px solid rgba(0, 0, 0, 0.06);

  .inspector-header {
    @include size(100%, 56px);
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border-bottom: 1px solid $border-grey;

    img {
      opacity: .35;
    }
  }


  .footer {
    margin-top: auto;
  }
}

.inspector-hide-show-button {
  position: absolute;
  right: 5px;
  bottom: 5px;

  &.hide {
    right: 5 + $inspector-panel-width;
  }

  transition: right .2s;
}

.inspector-body {
  padding-left: 24px;
  padding-right: 24px;

  .MuiAccordion-root {
    border-bottom: 1px solid $border-grey;
  }

  .MuiAccordion-root::before {
    background-color: transparent !important;
  }

  .MuiAccordionDetails-root {
    padding: 5px !important;
    padding-bottom: 2px !important;
  }

  .Mui-expanded {
    margin: 0 !important;
  }

  .MuiAccordionSummary-root {
    @include font(Inter-Regular, 15px, 24px);
    padding: 0px !important;

    &.Mui-expanded {
      min-height: 48px;
    }
  }

  .MuiAccordionSummary-content {
    margin: 0;
    min-height: 48px;
    display: flex;
    align-items: center;

    &.Mui-expanded {
      margin: 0;
    }
  }

  .header {
    margin-top: 16px;
    border-bottom: 0px;
    border-bottom: 1px solid $border-grey;

    h3 {
      color: black;
    }
  }

  .MuiInputBase-input {
    text-overflow: ellipsis;
  }

  .error {
    color: #ff1744 !important;
  }

  .input-box {
    height: 56px;
    border-bottom: 1px solid $border-grey;
    flex-shrink: 0;

    font-size: 15px;
    line-height: 24px;

    display: flex;
    justify-content: space-between;
    align-items: center;

    label,
    .MuiInputBase-root {
      @include font(Inter-Regular, 15px, 24px);
      flex: 1;
    }

    &.disabled {
      label {
        color: $disabled !important
      }
    }

    input {
      text-align: end;
      color: $exhibify-grey;
    }

    &.left {
      input {
        text-align: start;
        color: black;
      }
    }

    &.error {
      border-bottom: 1px solid #ff1744;

      label {
        color: #ff1744;
      }
    }

    .end-icon {
      @include size(24px);
      margin: 0 0 0 auto;

      .MuiSvgIcon-root {
        font-size: 20px;
      }
    }

    &.large-input-box{
      height: 60px;
      flex-direction: column;
      align-items: start;
      margin-top: 10px;
    }
  }

  .input-box-border {
    border-bottom: 1px solid $border-grey;
    flex-shrink: 0;
  }

  .color-picker-container {
    input {
      max-width: 70px;
    }

    .kSxvBW {
      flex-direction: row-reverse;
    }

    div[role="button"] {
      display: flex;
      align-items: center;
    }
  }

  .MuiButtonBase-root {
    &.selected {
      background-color: $grey;
    }

    .icon {
      @include size(25px);
    }
  }

  .preview-image-list {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    // padding: 16px 0;
  }


  .preview-image {
    @include size(82px);
    margin-bottom: 16px;
    @include border-radius(4px);
    border: solid 1px rgba(0, 0, 0, 0.16);
    position: relative;
    overflow: hidden;

    .preview-image-tint {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      mix-blend-mode: multiply;
      z-index: 1;
    }

    &.draggable {
      cursor: move;
      /* fallback if grab cursor is unsupported */
      cursor: grab;
      cursor: -moz-grab;
      cursor: -webkit-grab;
    }

    &.draggable:active {
      cursor: grabbing;
      cursor: -moz-grabbing;
      cursor: -webkit-grabbing;
    }

    // (Optional) Apply a "closed-hand" cursor during drag operation.
    &.dragging {
      cursor: grabbing;
      cursor: -moz-grabbing;
      cursor: -webkit-grabbing;
    }

    &.clickable {
      cursor: pointer;
    }

    img {
      @include border-radius(4px);
      @include size(82px);
      object-fit: contain;
      transition: transform 0.25s;
    }

    .zoom-img {
      transform: scale(2);
    }

    &:hover .zoom-img {
      transform: scale(5);
    }


    &:nth-child(2n+1) {
      margin-right: 16px;
    }

    &.hidden {
      visibility: hidden;
    }

    &.dissabled {
      opacity: 0.4;
    }

    &.selected {
      outline: solid 4px rgb(63, 155, 254);
      outline-offset: -2px;
    }
  }

  .full-image{
    width: 100%;
  }
}