@import 'animation';
@import 'input';
@import 'spacing';
@import 'transition';

//  Rounded corners
//
//  Example:
//  .somediv {
//    @include border-radius(5px);
//  }
//
@mixin border-radius($arc) {
  -moz-border-radius: $arc;
  -webkit-border-radius: $arc;
  border-radius: $arc;
}

//  Dimensions
//
//  Example:
//    .first-element {
//      @include size(2em);
//    }
//
//    .second-element {
//      @include size(auto, 10em);
//    }

@mixin size($width, $height: $width) {
  width: $width;
  height: $height;
}

@mixin transform($property) {
  -webkit-transform: $property;
  -ms-transform: $property;
  transform: $property;
}

@mixin filters($content) {
  filter: $content;
  -webkit-filter: $content;
  -moz-filter: $content;
  -o-filter: $content;
  -ms-filter: $content;
}

@mixin numberInputNoArrows() {
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  -moz-appearance: textfield;
}
