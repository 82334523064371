.color-picker-container{
  display: flex;
  align-items: center;
}

.input-color{
    margin-right: 8px;
    // -webkit-appearance: none;
    border: none;
    width: auto;
    height: auto;
    cursor: pointer;
    background: none;
    &::-webkit-color-swatch-wrapper {
      padding: 0;
      width: 25px;
      height: 25px;
      -webkit-box-shadow: 0px 9px 8px -3px rgba(0,0,0,0.14); 
      box-shadow: 0px 9px 8px -3px rgba(0,0,0,0.14);
    }
    &::-webkit-color-swatch {
      border: 1px solid #bfc9d9;
      border-radius: 4px;
      padding: 0;
    }
}

.input-text{
    border: none;
    width: 100%;
    height: 25px;
    font-size: 15px;
    text-transform: uppercase;
    box-shadow: bottom gray 1px;
}
.input-text:focus{
    outline: none;
}

.colorPicker{
  cursor:pointer;
  -webkit-box-shadow: 0px 9px 8px -3px rgba(0,0,0,0.14); 
  box-shadow: 0px 9px 8px -3px rgba(0,0,0,0.14);
  border: 0px solid #767676;
  border-radius: 4px;
  padding: 0px;
  width: 24px;
  min-width: 24px;
  height: 24px;
}

.swatch{
  background: #fff;
  border-radius: 1px;
  -webkit-box-shadow: 0px 9px 8px -3px rgba(0,0,0,0.14); 
  box-shadow: 0px 9px 8px -3px rgba(0,0,0,0.14);
  display: inline-block;
  cursor: pointer;
  width: 24px;
  height: 24px;
  display: flex;
}

.cover{
  position: fixed;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
}

.color{
  width: 100%;
  height: 100%;
  border-radius: 2px;
}
.popover{
  position: absolute;
  z-index: 2;
}

.p-colorPicker{
  text-transform: uppercase;
  color: #899aa3;
  padding-right: 10px;
  cursor:pointer;
}