@font-face {
  font-family: Inter-Regular;
  src: local(Inter-Regular), url("../../assets/fonts/Inter-Regular.otf");
}

@font-face {
  font-family: Inter-Medium;
  src: local(Inter-Medium), url("../../assets/fonts/Inter-Medium.otf");
}

@font-face {
  font-family: Inter-SemiBold;
  src: local(Inter-SemiBold), url("../../assets/fonts/Inter-SemiBold.otf");
}

@font-face {
  font-family: Biotif-Regular;
  src: local(Biotif-Regular), url("../../assets/fonts/Degarism Studio - Biotif-Regular.otf");
}

@font-face {
  font-family: Biotif-Medium;
  src: local(Biotif-Medium), url("../../assets/fonts/Degarism Studio - Biotif-Medium.otf");
}

@font-face {
  font-family: Biotif-Bold;
  src: local(Biotif-Bold), url("../../assets/fonts/Degarism Studio - Biotif-Bold.otf");
}

@font-face {
  font-family: Biotif-SemiBold;
  src: local(Biotif-SemiBold), url("../../assets/fonts/Degarism Studio - Biotif-SemiBold.otf");
}

@mixin font($name: Inter-Regular, $size: 14px, $height: normal, $spacing: normal, $color: black) {
  font-family: $name, Arial, Helvetica Neue, Helvetica, sans-serif;
  font-size: $size;
  line-height: $height;
  letter-spacing: $spacing;
  color: $color;
  font-style: normal;
}