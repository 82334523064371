.app-canvas {
  position: absolute;
  left: $tools-panel-width-collapsed;
  top: 0;
  right: $inspector-panel-width;
  bottom: 0;

  &.expanded {
    right: 0;
  }
}

.floor-plan {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}