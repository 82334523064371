.tools-panel {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  box-sizing: border-box;
  width: $tools-panel-width-collapsed;
  height: 100vh;
  padding: 0px;
  border-right: 1px solid rgba(0, 0, 0, 0.06);
  transition: width .5s;
  // background-color: rgba(240, 255, 255, 0.632);
  background-color: white;
  position: relative;
  z-index: 1;

  overflow-x: hidden;
  overflow-y: auto;

  &:hover {
    width: $tools-panel-width-expanded;

    .collapsed {
      transform: translateX(36px);
      opacity: 0 !important;
    }

    .expanded {
      opacity: 1 !important;
    }
  }

  .logo {
    min-height: 25px;
    border-bottom: 1px solid $border-grey;
    margin: 10px;
    position: relative;

    .logo-image {
      min-height: 25px;
      max-height: 25px;
      position: absolute;

      &.collapsed {
        opacity: 1;
        transition: transform .5s, opacity .5s;
        transition-delay: opacity .3;
      }

      &.expanded {
        width: 160px;
        max-height: 50px;
        opacity: 0;
        transition: opacity .5s;
      }
    }

  }

  .tool-btn {
    z-index: 5;
    @include size(100%, $tools-panel-width-collapsed);
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border-bottom: 1px solid $border-grey;
    padding: 10px;

    .start-icon {
      @include size(24px);
      margin: 0 14px 0 0;

      &.MuiSvgIcon-root {
        @include size(24px);
        margin-right: 18px;
      }
    }

    span {
      @include font(Inter-Regular, 15px, 24px);
      white-space: nowrap;
    }

    .end-icon {
      @include size(24px);
      margin: 0 0 0 auto;
    }

    &.Mui-disabled {
      // background-color: red;

      span {
        color: #ccc
      }

      img {
        opacity: 0.5;
      }
    }

    &.selected {
      transition: background-color .5s;
      background-color: #ccc;
    }
  }
}