.full-screen-container {
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    position: absolute;
    z-index: 10;
    background-color: whitesmoke;

    .header {
        margin-top: 10px;
        display: flex;
        flex-direction: column;
        align-items: center;

        .logo {
            height: 40px;
        }
    }

    .dots-pattern {
        position: absolute;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        background: radial-gradient(rgb(146, 146, 146) 1.25px, transparent 1px);
        background-size: 16px 16px;
        z-index: -1;
    }

    .centered-wrapper {
        display: flex;
        position: absolute;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        h1 {
            @include font(Inter);
            color: black;
            font-size: 40px;
            font-weight: 700;
        }

        h2 {
            @include font(Inter);
            color: rgb(77, 77, 77);
            font-size: 25px;
            font-weight: 700;
        }
    }

}

.full-screen-invisible {
    opacity: 0;
    transition: opacity 1s;

    &.displayNone {
        display: none;
    }
}


.progress-bar {
    width: 60%;
    margin-top: 10px;
    border-radius: 5px;
    background-color: #777777;
    background: #777777;
    box-shadow: 0px 10px 0px #b9b9b9 inset;
}

.progress-bar .progress-bar-full {
    font-family: Inter, Arial, Helvetica Neue, Helvetica, sans-serif;
    height: 10px;
    border-radius: 5px;
    background-color: #000000;
    background: #000000;
    color: #000000;
    text-align: center;
    line-height: 50px;
    font-size: 12px;
}