$black: #000000;
$white: #ffffff;

$border-grey: rgba(0, 0, 0, 0.06);

$aqua-dark: #000000;
$aqua: #333;
$aqua-light: #d7f4ff;
$aqua-soft: #c8dce4;

$orange: #ff9800;

$grey: #ddf;

$error: #a5000c;
$error-bg: #fff0f0;
$error-bg-light: lighten($error, 60%);
$delete-text: #f55858;
$delete-bg: rgba(255, 0, 0, 0.08);
$success: #2ea321;
$success-border: rgba($success, 0.24);
$success-bg: rgba($success, 0.08);
$success-bg-light: lighten($success, 60%);
$warning: #eba313;
$warning-border: rgba($warning, 0.24);
$warning-bg: rgba($warning, 0.08);
$warning-bg-light: lighten($warning, 60%);
$exhibify-grey: #899aa3;
$disabled: #899aa3;
$disabled-border: rgba($disabled, 0.24);
$disabled-bg: rgba($disabled, 0.08);
$disabled-bg-light: lighten($disabled, 60%);
$info: #00559e;
$info-bg: #e9f5fc;
$info-bg-light: lighten($info, 60%);
