.print-page {
    overflow: auto;

    h2, h3 {
        margin-top: 20px;
    }

    .print-container {
        position: absolute;
        overflow: auto;
        top: 0px;
        left: 0px;
        right: 0px;
        bottom: 60px;
    }

    .print-content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }


    @media print {
        overflow: visible;
        // background-color: red;
        // padding: 20px;

        .print-container {
            overflow: visible;
            bottom: 0px;
        }

        .print-group {
            break-inside: avoid;
        }

        .footer {
            display: none;
        }

        footer {
            display: none;
        }


        .blueprint-image {
            max-height: 800px !important;
        }
    }


    footer {
        position: fixed;
        bottom: 0;
        width: 100%;
        height: 60px;
        display: flex;
        align-items: center;
        justify-content: space-around;

        border-top: 1px solid;
        // border-top: 10px;
        border-color: gainsboro;
        // display: none;


        img {
            height: 30px;
        }
    }
}